<template>
  <v-container fluid>
    <v-card>
      <v-card-title>Pievienojiet uzdevumu</v-card-title>
      <v-card-text>
        <v-form v-model="valid">
          <v-row>
            <v-col cols="12" md="6" lg="4">
              <v-autocomplete
                :items="companies"
                item-text="name"
                item-value="id"
                v-model="form.company_id"
                label="Uzņēmumi"
                outlined
                clearable
                @change="getAdvertisements()"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6" lg="4">
              <v-autocomplete
                :items="advertisements"
                item-text="name"
                item-value="id"
                v-model="form.advertisement_id"
                label="Reklāmas"
                outlined
                clearable
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6" lg="4">
              <v-autocomplete
                :items="users"
                item-text="name"
                item-value="id"
                v-model="form.user_id"
                label="Menedžers"
                outlined
                clearable
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field label="Nosaukums" v-model="form.name" outlined autofocus required :rules="rules"/>
            </v-col>
            <v-col cols="12" md="4">
              <v-dialog
                ref="dialog"
                v-model="modal"
                :return-value.sync="form.date_start"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="form.date_start"
                    label="Sākuma datums"
                    prepend-icon="fa-calendar"
                    readonly
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    :rules="rules"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="form.date_start" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal = false">Cancel</v-btn>
                  <v-btn text color="primary" @click="$refs.dialog.save(form.date_start)">OK</v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-col cols="12" md="4">
              <v-dialog
                ref="dialog2"
                v-model="modal2"
                :return-value.sync="form.date_end"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="form.date_end"
                    label="Datuma beigas"
                    prepend-icon="fa-calendar"
                    readonly
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    :rules="rules"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="form.date_end" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal2 = false">Cancel</v-btn>
                  <v-btn text color="primary" @click="$refs.dialog2.save(form.date_end)">OK</v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-col cols="12" sm="9">
              <v-textarea label="Apraksts" v-model="form.description" outlined/>
            </v-col>
            <v-col cols="12" sm="3">
              <v-color-picker
                v-model="form.color"
                mode="hexa"
                hide-inputs
                dot-size="30"
              ></v-color-picker>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <AlertResponse :response="alert"/>
      <v-card-actions>
        <v-btn :disabled="!valid" color="primary" @click="save">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import axios from '@/axios'
import AlertResponse from '@/components/AlertResponse'

export default {
  name: 'TaskCreate',
  components: {
    AlertResponse
  },
  data: () => ({
    valid: false,
    rules: [
      value => !!value || 'Nepieciešams'
    ],
    modal: false,
    modal2: false,
    alert: null,
    form: {
      user_id: null,
      advertisement_id: null,
      company_id: null,
      name: '',
      description: '',
      date_start: '',
      date_end: '',
      color: '',
      priority: 0,
      status: 1
    },
    advertisements: [],
    companies: [],
    users: []
  }),
  methods: {
    getCompanies () {
      axios.get('companies').then(response => {
        this.companies = response.data.data
      })
    },
    getAdvertisements () {
      axios.get('companies/' + this.form.company_id + '/advertisements').then(response => {
        this.advertisements = response.data
      })
    },
    getUsers () {
      axios.get('users').then(response => {
        this.users = response.data
      })
    },
    save () {
      if (this.valid === true) {
        axios.post('tasks', this.form).then(response => {
          this.alert = response.data
          this.valid = false
          setTimeout(() => this.$router.push({ name: 'taskIndex' }), 2000)
        }).catch(error => {
          this.alert = error.data
          this.valid = true
        })
      }
    }
  },
  created () {
    this.form.advertisement_id = this.$route.params.advertisement_id
    this.form.company_id = this.$route.params.company_id
    this.getCompanies()
    // this.getAdvertisements()
    this.getUsers()
  }
}
</script>

<style scoped>

</style>
